const CARSALE = [
    {
        url: 'A-class.jpeg',
        name: 'Mercedez-Benz',
        desc: 'A-Class',
        offer: '$88,000',
        deal: '$93,000',
        km: '16,663 km',
        area: 'Victoria'
    },
    {
        url: 'jeep.jpeg',
        name: 'Jeep',
        desc: 'Cherokee',
        offer: '$32,000',
        deal: '$35,200',
        km: '19,000 km',
        area: 'Western Australia'
    },
    {
        url: 'nissan.jpg',
        name: 'Nissan',
        desc: 'Pathfinder',
        offer: '$12,000',
        deal: '$15,000',
        km: '194,565 km',
        area: 'Western Australia'
    },
    {
        url: 'ford.jpeg',
        name: 'Ford',
        desc: 'Falcon',
        offer: '$28,000',
        deal: '$30,622',
        km: '135,000 km',
        area: 'Victoria'
    },
    {
        url: 'mitsubishi.jpg',
        name: 'Mitsubishi',
        desc: 'ASX',
        offer: '$23,500',
        deal: '$26,100',
        km: '37,000 km',
        area: 'Western Australia'
    },
    {
        url: 'toyota.jpeg',
        name: 'Toyota',
        desc: 'Yaris',
        offer: '$6,000',
        deal: '$8,149',
        km: '156,028 km',
        area: 'Victoria'
    },
    {
        url: 'holden.jpeg',
        name: 'Holden',
        desc: 'Ute',
        offer: '$33,000',
        deal: '$35,000',
        km: '91,000 km',
        area: 'Victoria'
    },
    {
        url: 'isuzu.jpeg',
        name: 'Isuzu',
        desc: 'MU-X',
        offer: '$42,000',
        deal: '$44,000',
        km: '75,624 km',
        area: 'Victoria'
    },
    {
        url: 'kia.jpg',
        name: 'Kia',
        desc: 'Cerato',
        offer: '$7,000',
        deal: '$8,750',
        km: '82,200 km',
        area: 'Western Australia'
    },
    {
        url: 'mazda.jpeg',
        name: 'Mazda',
        desc: '3',
        offer: '$22,500',
        deal: '$23,888',
        km: '13,600 km',
        area: 'Victoria'
    },
    {
        url: 'toyota-prado.png',
        name: 'Toyota',
        desc: 'Land Cruiser Prado',
        offer: '$72,000',
        deal: '$73,250',
        km: '19,000 km',
        area: 'New South Wales'
    },
    {
        url: 'kia-cerato.jpeg',
        name: 'Kia',
        desc: 'Cerato',
        offer: '$20,500',
        deal: '$21,700',
        km: '38,000 km',
        area: 'Victoria'
    }
]

const QUESTIONS = [
    {
        id: 'question-1',
        question: `Where is AutoFlip based?`,
        answer: `AutoFlip is based in New Zealand and Australia. Our head offices are in Auckland and Melbourne. `,
        title: 'About AutoFlip',
        isActive: false,

    },
    {
        id: 'question-2',
        question: `How long has AutoFlip existed?`,
        answer: `AutoFlip was founded in 2019 in Australia. It's backed by IDOM Innovations, the corporate venture arm of Tokyo Stock Exchange listed IDOM Inc. and boasts more than 25 years’ experience in the used car industry.`,
        isActive: false
    },
    {
        id: 'question-3',
        question: `Does AutoFlip have buyers all across New Zealand?`,
        answer: `Yes, AutoFlip has buyers all across New Zealand. We have a large growing network of Registered Motor Traders and wholesale buyers who purchase cars across NZ.`,
        isActive: false
    },
    {
        id: 'question-4',
        question: `Is AutoFlip safe?`,
        answer: `AutoFlip is safe and secure. We don't share your personal details during the sales negotiation process. When you accept an offer, we pass the information required to finalise the sale to the successful buyer only. Payment is transferred directly from the successful buyer to you. At no time does AutoFlip hold your funds.
        <br/><br/>
        AutoFlip buyers are required to hold a motor car traders licence ensuring you greater protection. Using AutoFlip also means you're safe from online scammers, and you don't need to worry about meeting strangers or having them visit your home.`,
        isActive: false
    },
    {
        id: 'question-5',
        question: `Is AutoFlip legit?`,
        answer: `AutoFlip is a legitimate and trusted business operating across Australia and New Zealand, with the backing of Tokyo Stock Exchange listed IDOM Innovations.`,
        isActive: false
    },
    {
        id: 'question-6',
        question: `Why use AutoFlip?`,
        answer: `AutoFlip is for people who want to sell their cars for a competitive used car price, fast and easy. We remove the hassle and do all the hard work for you.
        <br/><br/>
        Listing your car with AutoFlip gives you access to our large network of licensed dealers and we do the negotiating for you, so you don't need to leave your home. Genuine offers can be received within hours of listing so just sit back and we'll contact you when there's a winning bid.
        <br/><br/>
        When you've accepted an offer, the successful buyer arranges the transfer of payment and collection of your vehicle. No contact with the public or exposing yourself to potential scammers.`,
        title: 'Why Autoflip',
        isActive: false,
        isWhySection: true,
    },
    {
        id: 'question-7',
        question: `How is AutoFlip different from other car selling sites?`,
        answer: `AutoFlip is quick, simple, and easy with a team focused on getting you the best trade in price. 
        Unlike some other sites where you receive an offer from a single dealer or wholesale buyer, we provide you access to our extensive network of serious buyers. They then competitively bid on your car, meaning you get the best used car price without having to drive around to multiple locations. Successful buyers will also coordinate collection of your vehicle at no cost, meaning you don't even have to leave your couch.`,
        isActive: false,
        isWhySection: true,
    },
    {
        id: 'question-8',
        question: `How does AutoFlip compare to selling directly to a car dealership?`,
        answer: `Selling your car directly to a car dealership is a legitimate way to sell your car. However, using AutoFlip, means you gain access to our network of many car dealers and wholesalers. 
        So, if you want a competitive used car trade in price and don't have the time or inclination to drive around to multiple dealerships, AutoFlip is the way to go.`,
        isActive: false,
        isWhySection: true,
    },
    {
        id: 'question-9',
        question: `How does AutoFlip compare to selling my car privately?`,
        answer: `Selling your car privately comes with more effort as you take full responsibility for the sale. Everything from listing the vehicle, paying for advertising costs, answering queries, dealing with time wasters, exposing yourself to scammers and having strangers come to your home. 
        How long it takes to sell your car is also unknown and there's an expectation to get a high price, given the extra risk and hassle.
        <br/><br/>
        AutoFlip removes the hassle and the unknown. You can get a genuine offer from a trusted licensed buyer quickly and receive payment fast.`,
        isActive: false,
        isWhySection: true,
    },
    {
        id: 'question-10',
        question: `How is my car's final sale price determined?`,
        answer: `The final sale price of your car is determined by the highest offer received from our trusted network of licensed buyers. 
        The price will depend on your car's condition, its unique features and the current market demand.`,
        title: 'Selling with AutoFlip',
        isActive: false
    },
    {
        id: 'question-11',
        question: `What paperwork do I need to sell my car?`,
        answer: `AutoFlip will prepare the relevant paperwork, as required by your location and the successful buyer of your vehicle. 
        This will be sent to you for completion and signing.`,
        isActive: false
    },
    {
        id: 'question-12',
        question: `How do I get paid when I sell my car?`,
        answer: `The successful buyer will get in touch with you to arrange the transfer of payment and free collection of your vehicle.`,
        isActive: false
    },
    {
        id: 'question-13',
        question: `Can I sell my car if I still owe money through finance?`,
        answer: `Yes, you can still sell your car with AutoFlip if you owe money through finance. We can assist the successful buyer and your finance company.`,
        isActive: false
    },
    {
        id: 'question-14',
        question: `How long does it take to sell my car through AutoFlip?`,
        answer: `Once your car is listed, genuine offers can be received within hours. Selling your car through AutoFlip is quick, simple, and easy. We ask for some basic car information including photos and then we do the rest.
        <br/><br/>
        Your car will be advertised to our large network of trusted buyers, we'll collect competitive offers and respond to you with the best price. If you accept the offer, the successful dealer will arrange a convenient time to collect your car and organise payment.
        <br/><br/>
        If an offer doesn't reach your expectations, our team will discuss further options to assist you in reaching a successful sale.`,
        isActive: false
    },
    {
        id: 'question-15',
        question: `How can I sell my car fast?`,
        answer: `Selling your car with AutoFlip is simple, easy, and fast. As soon as you've entered your vehicle information and spoken with one of our friendly customer service team members, we can list your car. 
        Then our extensive dealer network gets to competitively bid on your vehicle while you sit back and relax. Genuine offers can come in within hours of listing.
        <br/><br/>
        If an offer doesn't reach your expectations, our team will discuss further options to assist you in reaching a successful sale.
        <br/><br/>
        Once sold, the successful buyer will contact you to arrange free collection of your vehicle and transfer of payment.`,
        isActive: false
    },
    {
        id: 'question-16',
        question: `How does AutoFlip estimate the value of my car?`,
        answer: `AutoFlip estimates the value of your car based on the vehicle details you provide such as make, model, year, odometer reading, condition etc. 
        The estimate is based on the New Zealand market value of your vehicle at that point in time.
        <br/><br/>
        However, to get the most accurate valuation of your car's worth, its best to speak with one of our experienced team who can provide a personalised and more accurate price guide based on real time and your unique car details.`,
        isActive: false
    },
    {
        id: 'question-17',
        question: `Can I sell my car to a dealer through AutoFlip?`,
        answer: `Yes, the bidders for your car will only be New Zealand licensed dealers interested in purchasing used cars. 
        Our large network of trusted dealers is growing rapidly, so you can be sure we'll have a buyer waiting to bid on your car!`,
        isActive: false
    },
    {
        id: 'question-18',
        question: `Who will buy my car through AutoFlip?`,
        answer: `Your car will be offered to our extensive network of dealers throughout New Zealand. AutoFlip buyers are required to be registered motor vehicle traders, ensuring you greater protection. The successful buyer will arrange to collect your car at a time that's convenient to you and at no charge. 
        They'll also pay you directly, so you don't need to worry about delayed payments or additional people getting in the way of receiving your funds.`,
        isActive: false
    },
    {
        id: 'question-19',
        question: `My car is advertised online already, how does AutoFlip get me a better deal?`,
        answer: `At AutoFlip, we tap into our extensive network of trusted dealers across New Zealand and negotiate the best price for your car on your behalf. 
        You can receive a genuine best trade in price offer within hours of listing.`,
        isActive: false
    },
    {
        id: 'question-20',
        question: `What are AutoFlip's fees?`,
        answer: `AutoFlip will charge a small success fee when you accept an offer from the successful buyer to purchase your car. The success fee will be a percentage of the total sale price, please contact info@autoflip.co.nz for further details. 
        AutoFlip earns its revenue based on you successfully selling your vehicle.
        <br/><br/>
        And if there's no sale, there's no fee. It won't cost you anything.
        <br/><br/>
        With AutoFlip, there's:
        <br/><br/>
        No listing fee
        <br/><br/>
        No advertising fee
        <br/><br/>
        No transport costs
        <br/><br/>
        No Warrant of Fitness required
        <br/><br/>
        No deal = no fee
        <br/><br/>
        No nasty surprises`,
        isActive: false
    },
    {
        id: 'question-21',
        question: `What happens after I accept an offer?`,
        answer: `Once you've accepted an offer, AutoFlip will coordinate payment of the success fee (average success fee is 1% of the sale price*) and connect you with the successful buyer. The buyer will transfer your payment and schedule a time to collect your vehicle. 
        You don't need to leave your home and there's no transportation costs to worry about.
        <br/><br/>
        <i>*actual percentage will vary depending on the sale price of your car.</i>`,
        isActive: false
    },
]

const TESTIMONIAL = [
    {
        feedback: `AutoFlip is amazing! I sold my used car through their services recently and was very impressed! It's an easy process and I'd recommend it to anyone!<br/><br/>Thanks again AutoFlip!`,
        name: 'Loretta H.',
    },
    {
        feedback: `I cannot speak highly enough about the stress-less and smooth method of selling my car! The contacts and obviously the trust [AutoFlip] has built in the industry has been shown through the quick response and sale!`,
        name: 'Michael C.',
    },
    {
        feedback: `In 24 hours my car was sold, money in the bank account, and the car was collected.<br/><br/>Yep, it all seemed a bit of a fairy-tale, but I can assure you I'll never advertise my cars again on any other forum.`,
        name: 'Jip B.',
    },
    {
        feedback: `My experience with AutoFlip was an easy and trouble-free experience - the process was quick & successful - it was completed within a few days with a successful sale of my vehicle.`,
        name: 'John B.',
    },
    {
        feedback: `This is the second vehicle [AutoFlip] has sold for us now. Once again, the car sold within a few hours, and we got more than expected.<br/><br/> The whole process was stress free and easy, we were contacted by the dealer, and the car was paid for and collected. We would recommend [AutoFlip] to anybody selling their car.`,
        name: 'Bobby B.',
    },
    {
        feedback: `I had my car for sale privately for a few months with no interest. As soon as [AutoFlip] took over and worked their magic I ended up with more cash in my pocket. It was super easy.<br/><br/> I will definitely use AutoFlip again...<br/><br/>Thanks for your great service!`,
        name: 'Natasha B.',
    },
    {
        feedback: `Absolute champions! [AutoFlip] called me the same day I submitted the inquiry. Car was up for sale that day and had an offer at the reserve I set the following day.<br/><br/> AutoFlip arranged for someone to collect it and I was paid in full the following day.<br/><br/> Would 100% recommend!`,
        name: 'Luke W.',
    },
    {
        feedback: `I was recommended AutoFlip by a colleague and was BLOWN AWAY!<br/><br/>...not even 48 hours, very limited effort and a price we are so happy with! I would 10/10 recommend AutoFlip and use them again - WOW`,
        name: 'Cameron L.',
    },
]

export default {
    CARSALE: CARSALE,
    QUESTIONS: QUESTIONS,
    TESTIMONIAL: TESTIMONIAL,
}

export const CAR_REVIEWS = [
    {
        img: 'man_review.png',
        name: 'Rochelle',
        area: 'Western Australia',
        car: 'Hyundai i20',
        review: `Extremely prompt replying to emails and answering questions. 
        A great way to sell, especially when you aren't located in a big city, where selling privately might be more difficult. 
        Definitely recommend them.`
    },
    {
        img: 'man_review_2.png',
        name: 'Lisa',
        area: 'Western Australia',
        car: 'Toyota Corolla',
        review: `What a fantastic company! 5 stars from us. Not only was our car sold the same day but the customer service we received was outstanding. We would definitely recommend if you are looking for a hassle free way to sell your car!`
    },
    {
        img: 'man_review_3.png',
        name: 'Karyn',
        area: 'Victoria',
        car: 'Suzuki Swift',
        review: `Easy, simple and a great way to sell your car. Highly Recommend, they made the whole process hassle-free.`
    },
    {
        img: 'man_review_4.png',
        name: 'David',
        area: 'Victoria',
        car: 'Mazda MX5',
        review: `The team at AutoFlip were fantastic to deal with. The team didn't give up and found a buyer for my car, despite some challenges with it being registered interstate. The sale went through smoothly and the whole process was managed professionally and efficiently.`
    },
    {
        img: 'man_review_5.png',
        name: 'James',
        area: 'Victoria',
        car: 'Holden Barina',
        review: `VIC I live in Melbourne and tried to sell my car online. All I received was unrealistic offers from people over SMS. I then uploaded my car on AutoFlip and within 24 hours we settled on a price that was ~2k more than the best offer I had online.`
    },
    {
        img: 'man_review_6.png',
        name: 'Daniel',
        area: 'Western Australia',
        car: 'Mitsubishi Outlander',
        review: `I highly recommend giving AutoFlip a try. The whole process was so easy and the service was fantastic. Shane was awesome to deal with and helped secure the best price for my old Outlander.`
    }
]

export const VEHICLE_DETAILS = [
    {
        label: 'Odometer',
        key: 'kms'
    },
    {
        label: 'Colour',
        key: 'color'
    },
    {
        label: 'Sub model',
        key: 'badge'
    },
    {
        label: 'Transmission',
        key: 'transmission'
    },
    {
        label: 'Body type',
        key: 'bodyType'
    },
    {
        label: 'Drive type',
        key: 'drive'
    },
    {
        label: 'Model year',
        key: 'model_year'
    }
]
export const GUIDE_LINE_EXTERIOR = [
    {
        imgName: 'Front.jpg',
        text: 'Front'
    },
    {
        imgName: '34th_front_left.jpg',
        text: '3/4th front (left)'
    },
    {
        imgName: 'Side_left.jpg',
        text: 'Side (left)'
    },
    {
        imgName: '34_rear_left.jpg',
        text: '3/4th rear (left)'
    },
    {
        imgName: 'Rear.jpg',
        text: 'Rear'
    },
    {
        imgName: '34_rear_right.jpg',
        text: '3/4th rear (right)'
    },
    {
        imgName: 'Side_right.jpg',
        text: 'Side (right)'
    },
    {
        imgName: '34_front_right.jpg',
        text: '3/4th front (right)'
    },
    {
        imgName: 'Tyres.jpg',
        text: 'Tyres'
    },
    {
        imgName: 'Car_roof.jpg',
        text: 'Car roof'
    }
];
export const GUIDE_LINE_INTERIOR = [
    {
        imgName: 'Odometer.jpg',
        text: 'Odometer'
    },
    {
        imgName: 'Dashboard.jpg',
        text: 'Dashboard'
    },
    {
        imgName: 'Front_seats.jpg',
        text: 'Front seats'
    },
    {
        imgName: 'Rear_seats.jpg',
        text: 'Rear seats'
    },
    {
        imgName: 'Boot.jpg',
        text: 'Bootspace'
    },
    {
        imgName: 'Keys.jpg',
        text: 'Keys'
    },
    {
        imgName: 'Owners_manual.jpg',
        text: `Owner's manual & service history`
    },
];

export const EXTERIOR_RATE = [
    {
        val: 1,
        text: "Poor",
        desc: "Car has at least one of these conditions: Has major and minor scratches and dents, tyres need replacement very soon, brake pads need replacement immediately, peeling/flaking/cracking paint, major chips/cracks on the windshield, rust, cracked/broken headlamps, warped/bent rims, or major mechanical issues."
    },
    {
        val: 2,
        text: "Average",
        desc: "Car has at least one of these conditions: Has major and minor scratches or dents, had paint work to cover damages, tyres need replacement relatively soon, brake pads need replacement soon, needs some major and minor repairs or paint, headlamps are foggy, or has minor mechanical issues."
    },
    {
        val: 3,
        text: "Good",
        desc: "Car has at least one of these conditions: Has some visible scuffs and scratches, had paint touch-ups, tyres tread are less than 50% worn, has one minor crack/chip on the windshield, or needs some minor repairs or paint. No mechanical issues."
    },
    {
        val: 4,
        text: "Very Good",
        desc: "Has more than two minor dents or scratches, minor paint damage, and near-new tyres. No mechanical issues."
    },
    {
        val: 5,
        text: "Excellent",
        desc: "Car exterior is like new and may have 1-2 minor scratches or dents. No mechanical issues."
    },
];
export const INTERIOR_RATE = [
    {
        val: 1,
        text: "Poor",
        desc: "Car has at least one of these conditions: Has larger rips or tears, majorly visible stains, major electrical (e.g., window, heating) issues, major functional issues with the entertainment/navigation system, or any other major damages in the interior that requires repairs."
    },
    {
        val: 2,
        text: "Average",
        desc: "Car has at least one of these conditions: Requires some major or minor repairs due to minor rips or tears, visible stains, more than one minor electrical (e.g., window, heating) issues, minor functional issues with the entertainment/navigation system, or visible scuffs or scratches on its system screen."
    },
    {
        val: 3,
        text: "Good",
        desc: "Car has at least one of these conditions: Has minor scratches or scuffs, minor stain on seats, one minor electrical (e.g., window, heating) issue. No functional issues with the entertainment/navigation system but its system screen has minor scuffs or scratches."
    },
    {
        val: 4,
        text: "Very Good",
        desc: "Car has at least one of these conditions: Has more than two minor scratches, has some minor scratches on its entertainment/navigation screen. No rips or tears and no stains."
    },
    {
        val: 5,
        text: "Excellent",
        desc: "Car interior is like new and may have 1-2 minor damages or scratches. Entertainment/navigation screen has no scratches."
    },
];
export const VEHICLE_CONDITIONS = [
    {
        label: 'Service history',
        key: 'serviceHistory'
    },
    {
        label: 'Service history details',
        key: 'serviceHistoryDetail'
    },
    {
        label: 'Number of keys',
        key: 'key'
    },
    {
        label: 'Warrant of fitness',
        key: 'warranty'
    },
    {
        label: 'Registration status',
        key: 'registrationStatus'
    },
    {
        label: 'Tyres needing replacement',
        key: 'tyreReplacement'
    },
];