<template>
  <div class="quick-question">
    <div class="title-question mx-auto">
      <h2 class="fs-40 mb-2 font-weight-bold">
        FAQs
      </h2>
      <p class="fs-18 font-weight-thin">
        Have a question? View responses to commonly asked questions below. If
        you still have a query, <br v-if="!isMobile" />
        don't hesitate to contact us at
        <a :href="`mailto:${contactDetails.email}`" class="text-dark">
          <u class="font-weight-thin">{{ contactDetails.email }}</u>
        </a>
      </p>
    </div>
    <div class="content-question text-left">
      <div v-for="(data, index) in questions" :key="data.id" :class="data.isWhySection ? 'why-question' : ''">
        <div class="content-answer mx-auto">
          <h2 class="text-center mt-4 mb-3" v-if="data.title">
            {{ data.title }}
          </h2>
          <div
            class="d-flex justify-content-between align-items-center item"
          >
            <u
              class="mb-0"
              v-b-toggle="data.id"
              @click="active(index)"
              v-html="data.question"
            ></u>
            <span @click="active(index)">
              <img
                v-b-toggle="data.id"
                :src="require('@/assets/images/icons/ic_arrow_down.svg')"
              />
            </span>
          </div>
        </div>
        <b-collapse class="content-answer mx-auto" :id="data.id" :key="'answer-' + index">
          <p class="quick-answer font-weight-thin" v-html="data.answer"></p>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import constants from "@/config/constants";
import { mapState } from "vuex"

export default {
  name: "QuickQuestion",
  data() {
    return {
      questions: constants.QUESTIONS,
      isMobile: true,
    };
  },
  methods: {
    active(num) {
      this.questions[num].isActive = !this.questions[num].isActive;
    },
  },
  computed: {
    ...mapState({
      contactDetails: (state) => state.commonVariables.contactDetails,
    }),
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 1200px)");
    },
  },
  mounted() {
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });

    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>

<style lang="scss">
.quick-question {
  padding-bottom: 50px;
  background-color: #ffffff;

  .btn-sell {
    width: 159px;
    height: 51px;
    background-color: #2bb673;
    border: none;
    outline: none;
    color: white;
    border-radius: 8px !important;
    margin-top: 50px;
    font-size: 15px;
    &:hover {
      background-color: #20a766;
    }
  }

  .title-question {
    background-color: #f9fafb;
    line-height: 28px;
    padding-top: 27px;
    height: 220px;
  }

  .content-question {
    padding: 0 10px;
    margin-top: 30px;

    .item {
      min-height: 65px;

      u {
        color: #000000;
      }

      p {
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }

      span {
        .not-collapsed {
          transform: rotate(180deg);
          transition: ease-in-out 0.3s;
        }
        .collapsed {
          transition: ease-in-out 0.3s;
        }
      }

      img {
        width: 14px;
        height: 8px;
        cursor: pointer;
        margin-left: 5px;
        // transform: rotate(90deg);
      }
    }

    hr {
      border-color: #404040;
      margin-top: 0;
      margin-bottom: 0;
    }

    .quick-answer {
      font-size: 16px;
      margin-top: 16px;
      margin-bottom: 0 !important;
      padding-bottom: 41px;
    }

    .why-question {
      background-color: #f9fafb;
      h2 {
        &:first-child {
          padding-top: 31px;
        }
      }
      .item {
        &:last-child {
          padding-bottom: 40px;
        }
      }
    }
  }
  .content-answer {
    max-width: 414px;
  }
}

@media screen and(min-width: 768px) {
  .title-question {
    font-size: 36px !important;
    line-height: 34px !important;
    padding-top: 70px !important;
    width: 97% !important;
    height: 280px !important;
  }

  .content-question {
    padding: 0 !important;

    .item p {
      line-height: 20px !important;
      font-size: inherit !important;
    }

    .quick-answer {
      font-size: inherit !important;
      width: 90% !important;
    }

    span {
      &:hover {
        transform: translateY(-1.5px);
        transition: ease-in-out 0.2s;
      }
    }
  }
  
  .content-answer {
    max-width: 640px !important;
  }
}
</style>
