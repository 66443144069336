<template>
  <div class="rego-search-content mx-auto">
    <p class="fs-20 mb-2 title-rego-search">
      <b
        >Get the best price from our large network of dealers across
        New Zealand</b
      >
    </p>
    <div class="position-relative">
      <float-label label="Rego">
        <b-input
          v-model.trim="regoSearchInfo.regoNumber"
          placeholder="Enter rego"
          autocomplete="off"
          required
          @keyup.enter="submit"
          :class="{
            error: $v.regoSearchInfo.regoNumber.$error,
            'shadow-none mt-27': true,
          }"
        />
      </float-label>
      <p
        v-if="
          $v.regoSearchInfo.regoNumber.$dirty &&
            !$v.regoSearchInfo.regoNumber.required
        "
        class="msg-error mb-0"
      >
        <i>Please enter rego number</i>
      </p>
      <p
        v-else-if="
          $v.regoSearchInfo.regoNumber.$dirty &&
            !$v.regoSearchInfo.regoNumber.alphaNum
        "
        class="msg-error mb-0"
      >
        <i>Please enter only alphabet characters and numerics</i>
      </p>
      <p
        v-else-if="
          $v.regoSearchInfo.regoNumber.$dirty &&
            !$v.regoSearchInfo.regoNumber.maxLength
        "
        class="msg-error mb-0"
      >
        <i>Please enter 1 to 9 characters!</i>
      </p>
    </div>
    <div class="row justify-content-between">
      <div class="item col-5">
        <button
          @click="goTo"
          class="outline-btn-skip btn-skip fs-16 text-success"
        >
          <span class="font-weight-regular">Skip rego</span>
        </button>
      </div>
      <div class="item col-7">
        <button
          @click="regoSearch"
          :disabled="isLoadingApi"
          :class="[
            activeBtnGo ? 'active-btn-go' : 'default-btn-go',
            'btn-go fs-16 text-white',
          ]"
        >
          <b class="font-weight-regular" v-if="!isLoadingApi">Let's go</b>
          <b-spinner v-else label="Loading..." class="btn-loading"></b-spinner>
        </button>
      </div>
    </div>
    <div class="position-relative">
      <p class="mb-0 msg-api api-loading" v-if="isLoadingApi">
        <i>Searching for your details</i>
      </p>
      <p
        class="text-center mb-0 msg-api api-not-found"
        v-if="apiNotFound & !isLoadingApi"
      >
        <i>No results</i>
      </p>
    </div>
  </div>
</template>

<script>
import { required, maxLength, alphaNum } from "vuelidate/lib/validators";
import topPageService from "@/services/top-page";
import { ACTION_REGO_SEARCH } from "@/store/rego-search/actions";
import { SET_REGO_SEARCH_FLAG } from "@/store/info/actions";
import FloatLabel from "./FloatLabel";
import { LP_TYPE } from "@/config";
import { SET_INFO } from "@/store/info/actions";
import { logError } from "@/helper/utils";

export default {
  name: "RegoSearchForm",
  data() {
    return {
      regoSearchInfo: {
        regoNumber: null,
        leadSource: LP_TYPE,
      },
      isLoadingApi: false,
      apiNotFound: false,
    };
  },
  methods: {
    goTo() {
      this.$router.push({ name: "vehicle-info", query: this.$route.query });
    },
    submit() {
      this.regoSearch();
    },
    async regoSearch() {
      this.$v.regoSearchInfo.$touch();
      if (!this.$v.regoSearchInfo.$invalid) {
        this.$store.dispatch(SET_INFO, {});
        this.isLoadingApi = true;
        try {
          const searchResult = await topPageService.searchRego(
            {
              ...this.regoSearchInfo,
              locid: 2,
            }
          );
          this.isLoadingApi = true;
          if (searchResult.status === 201) {
            this.$toast.success("Success, we found your vehicle");
            // searchResult.data.location = this.regoSearchInfo.state;
            // searchResult.data.rego = this.regoSearchInfo.regoNumber;
            searchResult.data.rego = this.regoSearchInfo.regoNumber.toUpperCase();
            this.$store.dispatch(SET_REGO_SEARCH_FLAG, true);
            this.$store
              .dispatch(ACTION_REGO_SEARCH, searchResult.data)
              .then(() => {
                this.$router.push({ name: "vehicle-info", query: this.$route.query });
              });
          }
        } catch (err) {
          this.isLoadingApi = false;
          this.apiNotFound = true;
          if (err.response.status === 404) {
            this.$toast.warning(
              "We were unable to find your car information via our Rego search. Please fill in the information below."
            );
            this.$store
              .dispatch(ACTION_REGO_SEARCH, {
                rego: this.regoSearchInfo.regoNumber.toUpperCase(),
              })
              .then(() => {
                this.$router.push({ name: "vehicle-info", query: this.$route.query });
              });
          } else {
            logError(`Rego search error: ${err}`);
          }
        }
      }
    },
  },
  validations: {
    regoSearchInfo: {
      regoNumber: {
        required,
        alphaNum,
        maxLength: maxLength(9),
      },
    },
  },
  computed: {
    activeBtnGo: function() {
      return (
        this.regoSearchInfo.regoNumber &&
        !this.$v.regoSearchInfo.regoNumber.$error
      );
    },
  },
  mounted() {
    let regoInfo = this.$store.getters.regoSearch;

    if (Object.keys(regoInfo).length > 0) {
      this.regoSearchInfo.regoNumber = regoInfo.rego;
    }
  },
  components: {
    FloatLabel,
  },
};
</script>

<style lang="scss">
$white: #ffffff;
$loading_color: #74bd58;
$green: #00b86d;
$error_color: #c1272d;

$item_height: 50px;

.rego-search-content {
  max-width: 280px;
  background-color: $white;

  .title-rego-search {
    color: #292644;
    line-height: 24px;
  }

  input,
  select {
    color: #383c3f;
    max-width: 280px;
    height: $item_height;
    font-size: 16px;
    font-weight: bold;
    line-height: 21.6px;
    padding-left: 20px;
    padding-right: 10px;
    background-color: $white !important;
    border-radius: 10px;

    &:focus {
      box-shadow: none;
    }
  }

  .label-input {
    color: #999999;
    top: -10px;
    left: 10px;
    padding-left: 10px;
    padding-right: 14px;
    background-color: $white;
  }

  .no-rego {
    color: #b3b3b3;
    width: fit-content;
    text-decoration: underline;
    margin: 2px 0 9px auto;
  }

  .btn-go {
    width: 100%;
    height: $item_height;
    margin-top: 20px;
    border: none;
    border-radius: 10px !important;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: $green !important;
    }
  }

  .btn-skip {
    width: 100px;
    height: $item_height;
    margin-top: 20px;
    border: none;
    border-radius: 10px !important;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: $green !important;
    }

    &:hover {
      background-color: $green;
    }

    &:hover span {
      color: $white;
    }
  }

  .default-btn-go {
    background-color: #cccccc;

    &:active {
      background-color: #a9c6b9;
    }
  }

  .active-btn-go {
    background-color: $green;
  }

  .outline-btn-skip {
    background-color: $white;
    border: 1px solid $green !important;
  }

  .btn-loading {
    font-size: 16px;
    width: 30px;
    height: 30px;
  }

  input.error {
    caret-color: $error_color;
    border-color: $error_color !important;
  }

  .msg-error {
    font-size: 12px;
    color: $error_color;
    text-align: right;
    white-space: nowrap;
    margin-top: 2px;
  }

  .msg-api {
    position: absolute;
    font-size: 14px;
    line-height: 16.8px;
    top: 2px;
  }

  .api-loading {
    color: $loading_color;
    right: 0;
  }

  .api-not-found {
    color: $error_color;
    right: 0;
  }
}

@media screen and(min-width: 768px) {
  .rego-search-content {
    max-width: 340px;
    padding: 25px 30px 40px;
    border-radius: 20px;
    text-align: left;

    .title-rego-search {
      font-size: 24px !important;
    }
  }
}
</style>
